@import '../../variables.scss';


.nav-container {
    height: 85px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    font-family: 'Marck Script', cursive;
    
}

.logo {
    color: $darkPink;
    font-weight: bolder;
    font-size: 40px;
    text-shadow: -2px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    border: 1px solid black;
    padding: 05px 10px;
    margin-left: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    a {
        color: $darkPink;
        &:hover {
            color: $lightPurple;
        }
    }

}

nav {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    margin-right: 20px;
}

nav a {
    text-align: center;
    width: 120px;
    text-decoration: none;
    color: white;
    font-size: 25px;
    text-shadow: -2px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    border: 1px solid black;
    background-color: $darkPink;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    padding: 2px;

    &:hover {
        background-color: $lightPurple;
    }

}




.nav-btn {
    visibility: hidden;
    position: absolute;
    right: 1rem;
}


@include tablet {
    .nav-btn {
        visibility: visible;
        opacity: 1;
        color: $lightGrey;
     
    }

    nav {
        position: fixed;
        top: -100vh;
        right: 0;
        height: 40%;
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: white;
        transition: 0.3s;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        a {
            font-size: 20px;
            width: 100px;
        }
    }

    .responsive_nav {
        transform: translateY(100vh);
        margin-right: 0;
    }
}