@import '../../variables.scss';

.cloud-container {
  position: relative;
  display: inline-block;
  width: 350px;
  margin-top: -150px;
  margin-left: -100px;
  @include mobile {
    width: 250px;
    margin-left: -5%;
    margin-top: -100px;
  }

}

.cloud-container img {
  display: block;
  width: 100%;
  height: auto;

}
.cloud-text {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: $darkPink;
  text-shadow: -2px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 50px; 
  font-weight: bold;
  text-align: center;
  font-family: 'Marck Script', cursive;
  width: 90%;
  @include mobile {
    font-size: 40px; 

  }
}