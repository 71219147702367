@import '../../variables.scss';

@keyframes scale {
    50% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

//------Used for Navbar link destination------//
#about-nav {
    width: 100%;
    height: 1px;
}

#about {
    width: 90%;
    background-image: url('https://res.cloudinary.com/dwkg9jctn/image/upload/v1708627024/Portfolio/pink-clouds_j8omly_c_pad_w_1650_keavm8.jpg');
    background-size: cover;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    margin: auto;
    margin-top: 150px;
    border: 2px solid black;
    padding-bottom: 50px;

    @include mobile {
        margin-top: 120px;
    }
}

.bio-container {
    display: flex;
    justify-content: center;
    gap: 40px;

    @include tablet {
        flex-direction: column;
        align-items: center;
    }
}


.bio {
    background: linear-gradient($lightPurple, white);
    padding: 30px;
    border-radius: 30px;
    margin-top: -30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    text-align: center;
    font-size: 18px;
    border: 2px solid black;
    height: 210px;
    width: 210px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        width: 70%;
        height: 200px;
    }

    @include mobile {
        width: 65%;
    }

    .header {
        flex-direction: column;
        display: flex;
        justify-content: space-around;
        align-items: center;
        line-height: 27px;
        
    }

    h2 {
        font-size: 25px;
        color: $lightGrey;
        margin: 0;
        font-weight: 800;

        @include mobile {
            font-size: 18px;
        }
    }

    p {
        visibility: collapse;
        margin: 8px;
        font-family: 'Roboto Condensed', sans-serif;
    }

    .main-icon {
        color: white;
        font-size: 90px;

        @include tablet {
            font-size: 110px;
        }
        @include mobile {
            font-size: 90px;
        }
    }
}

.animate {
    color: rgb(101, 94, 160);
    animation: scale 1.7s infinite;
}

.flipped {
    min-height: 450px;
    height: 100%;

    @include tablet {
        min-height: 0;
        height: 60%;
    }

    p {
        visibility: visible;
    }

    .animate {
        visibility: collapse;
        height: 20px;
    }
}