@import '../../variables.scss';

//------Used for Navbar link destination------//
#home-nav {
    width: 100%;
    height: 1px;
}

#home {
    display: flex;
    background-image: url('https://res.cloudinary.com/dwkg9jctn/image/upload/v1708627024/Portfolio/pink-clouds_j8omly_c_pad_w_1650_keavm8.jpg');
    background-size: cover;
    justify-content: center;
    padding: 10px;
    gap: 50px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    margin: auto;
    margin-top: 100px;
    border: 2px solid black;
    padding-bottom: 20px;


    @include tablet {
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        width: 95%;
    }

    @include mobile {
      gap:40px
    }

    .typing-container {
        width: 600px;
        height: 450px;
        font-weight: 800;
        font-family: 'Marck Script', cursive;
        text-shadow: -2px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

        @include tablet {
            width: 450px;
            height: 150px;
        }
        @include mobile {
            width: 80vw;
            height: 20vh;
        }

        h1 {
            color: white;
            font-size: 80px;
            margin: 40px 0 20px 0;

            @include tablet {
                font-size: 60px;
                margin: 0;
            }
            @include mobile{
                font-size: 12vw;
            }
        }

        .fade-text {
            color: rgb(204, 198, 198);
            font-size: 45px;

            @include tablet {
                font-size: 35px;
            }

            @include mobile {
                font-size: 6vw;
            }

            &.fade-in {
                transition: opacity 1.5s ease;
            }
            &.fade-out {
                opacity: 0;
                transition: opacity 1.5s ease;
            }
        }
    }

    .home-pic {
        max-width: 400px;
        border-radius: 50px;
        border: 20px solid white;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

        @include tablet {
            width: 70%;
        }
    }
}