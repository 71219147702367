$lightPurple: #E3C7FF;
$darkPurple: #D5ABFF;
$lightMint: #B9EAD8;
$darkMint: #A0E0B2;
$lightBlue: #B8EBFB;
$darkBlue: #9EDBEE;
$lightGrey: #8C7F8B;
$darkGrey: #6d6d74;
$lightPink: #F8EAEA;
$darkPink: #fbd4d4;
$lightYellow: #fffec2;

@mixin mobile {
    @media (max-width: 500px) {
      @content;
    }
  }
  
  @mixin tablet {
    @media (max-width: 1024px) {
      @content;
    }
  }
  
  @mixin desktop {
    @media (max-width: 1366px) {
      @content;
    }
  }
