@import '../../variables.scss';

//------Used for Navbar link destination------//
#projects-nav {
    width: 100%;
    height: 1px;
}

#projects {
    width: 70%;
    background-image: url('https://res.cloudinary.com/dwkg9jctn/image/upload/v1708627024/Portfolio/pink-clouds_j8omly_c_pad_w_1650_keavm8.jpg');
    background-size: cover;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    margin: auto;
    margin-top: 150px;
    border: 2px solid black;
    padding-bottom: 50px;

    @include tablet {
        width: 90%;
        margin-top: 120px;
    }
}

.projects-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    gap: 50px;

    @include mobile {
        padding: 20px;
    }
}

.projects-container {
    display: flex;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    padding: 10px 30px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    border: 2px solid black;

    @include tablet {
        padding: 0;
        flex-direction: column-reverse;
    }

    @include mobile {
        border-radius: 30px;
    }
}

//---------------Website--------------//
.box-container {
    display: flex;
    flex-direction: column;
    padding: 20px 5px;

    @include tablet {
        padding: 30px;
    }

    @include mobile {
        padding: 5px 10px 10px 10px;
    }

}

.box-1 {
    width: 30%;
    height: 20px;
    border-top-left-radius: 80px 80px;
    border-top-right-radius: 80px 80px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
}

.box-2 {
    display: flex;
    margin: 0;
    justify-content: space-around;
    align-items: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;

    @include mobile {
        svg {
            font-size: 15px;
        }
    }
}

.link-bar {
    background-color: white;
    width: 70%;
    padding-left: 4px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 15px;

    @include mobile {
        font-size: 8px;
        width: 60%;
        height: 5%;
    }
}

.box-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;

    @include mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.website-pic {
    width: 400px;
    height: auto;
    padding: 20px;

    @include tablet {
        width: 90%;
        height: auto;
        padding: 15px 0;
    }
}

.purple {

    .box-1,
    .box-2 {
        background-color: $darkPurple;
    }

    .box-3 {
        background-color: $lightPurple;
    }
}

.mint {

    .box-1,
    .box-2 {
        background-color: $darkMint;
    }

    .box-3 {
        background-color: $lightMint;
    }
}

.blue {

    .box-1,
    .box-2 {
        background-color: $darkBlue;
    }

    .box-3 {
        background-color: $lightBlue;
    }
}

//---------------DESCRIPTION--------------//
.description-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px;
    width: 100%;
    height: 90%;

    svg {
        color: $darkPink;
        font-size: 70px;
        align-self: flex-end;
    }

    @include tablet {
        svg {
            padding-right: 25px;
        }
    }

    @include mobile {
        svg {
            font-size: 50px;
        }
    }
}

.text-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: $darkGrey;
    text-align: center;
    max-width: 410px;
    align-self: center;

    @include mobile {
        gap: 10px
    }

    .title {
        font-size: 40px;
        font-weight: bold;
        color: $darkPink;
        font-family: 'Marck Script', cursive;
        text-shadow: -2px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

        @include mobile {
            font-size: 38px;
        }
    }

    .desc {
        font-weight: bold;

        @include mobile {
            font-size: 15px;
            width: 80%;
            margin: auto;
        }
    }

    .stack {
        font-size: 16px;
        font-weight: bold;

        @include mobile {
            font-size: 12px;
        }
    }

    .site-links {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;

        a {
            background-color: #fbd4d4;
            font-weight: bold;
            width: 30%;
            text-align: center;
            padding: 10px;
            border-radius: 20px;
            text-decoration: none;
            color: $darkGrey;
            box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

            &:hover {
                background-color: $lightPurple;
            }

            @include mobile {
                font-size: 12px;
                width: 25%;
            }
        }
    }
}